import React from "react"
import { Link } from "gatsby"

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__body body-footer">
          <div
            data-da=".socials-footer,999.98,first"
            className="body-footer__logo"
          >
            <img
              data-da=".body-footer,767.98,first"
              className="logo-footer"
              src="/footer/footer-logo.svg?_v=1638553701131"
              alt="footer-logo"
            />
          </div>
          <div className="body-footer__info-links links-info">
            <div className="links-info__column info-column">
              <div
                data-spollers="479.98,max"
                className="info-column__spoller spollers"
              >
                <div className="spollers__item">
                  <button
                    type="button"
                    data-spoller
                    className="spollers__title spollers__title_arrowed"
                  >
                    Company
                  </button>
                  <div className="spollers__body">
                    <div className="body-spoller">
                      <a href="/terms-and-conditions/" className="body-spoller__links">
                        Terms and conditions
                      </a>
                      <a href="/privacy-policy/" className="body-spoller__links">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="links-info__column info-column">
              <div
                data-spollers="479.98,max"
                className="info-column__spoller spollers"
              >
                <div className="spollers__item">
                  <button
                    type="button"
                    data-spoller
                    className="spollers__title spollers__title_arrowed"
                  >
                    Help Centre
                  </button>
                  <div className="spollers__body">
                    <div className="body-spoller">
                      <Link to="/pricing/" className="body-spoller__links">
                        Pricing
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="links-info__column info-column">
              <div
                data-spollers="0,max"
                className="info-column__spoller spollers"
              >
                <div className="spollers__item">
                  <button
                    type="button"
                    data-spoller
                    className="spollers__title"
                  >
                    Download
                  </button>
                  <div className="spollers__body">
                    <div className="body-spoller">
                      <a href="https://play.google.com/store/apps/details?id=com.sol.pay" className="body-spoller__links">
                        <picture>
                          <source
                            srcSet="/footer/footer-google.webp"
                            type="image/webp"
                          />
                          <img
                            className="google-logo"
                            src="/footer/footer-google.png?_v=1638553701131"
                            alt="google-logo-link"
                          />
                        </picture>
                      </a>
                      <a href="https://apps.apple.com/ru/app/id1497336454" className="body-spoller__links">
                        <picture>
                          <source
                            srcSet="/footer/footer-apple.webp"
                            type="image/webp"
                          />
                          <img
                            className="apple-logo"
                            src="/footer/footer-apple.png?_v=1638553701131"
                            alt="google-logo-link"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-da=".links-info,767.98,last"
            className="body-footer__socials socials-footer"
          >
            <div className="socials-footer__icons icons-footer">
              <a href="https://www.instagram.com/solmate_sa/?hl=en/" className="icons-footer__icon">
                <img
                  className="footer-instagram"
                  src="/footer/footer-instagram.svg?_v=1638553701131"
                  alt="footer-insta-logo"
                />
              </a>
              <a href="https://twitter.com/solwallet?lang=en" className="icons-footer__icon">
                <img
                  className="footer-twitter"
                  src="/footer/footer-twitter.svg?_v=1638553701131"
                  alt="footer-twitter-logo"
                />
              </a>
              <a href="https://www.facebook.com/solmateSA" className="icons-footer__icon">
                <img
                  className="footer-facebook"
                  src="/footer/footer-facebook.svg?_v=1638553701131"
                  alt="footer-facebook-logo"
                />
              </a>
            </div>
          </div>
          <div className="body-footer__text">
            AK SOLWALLET SERVICES LIMITED (Registration Number 2019/570828/10)
            is a duly appointed juristic representative of PAL LIFE CC
            (Registration Number 2009/212012/23) which is an authorised
            Financial Services Provider under the Financial Advisory and
            Intermediary Services Act No 37 of 2002 – FSP 43259. As such AK
            SOLWALLET SERVICES LIMITED renders financial services to clients on
            behalf of PAL LIFE CC
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
