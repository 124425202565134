import React, { useEffect } from "react"
import { Link } from "gatsby"
import { menuOpen, bodyUnlock } from "../js/functions"

function Header() {
  useEffect(() => {
    menuOpen()
  }, [])
  const handleClick = () => {
    document.documentElement.classList.toggle("menu-open")
    bodyUnlock()
  }
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__body">
          <Link to="/" className="header__logo">
            <img
              className="logo-header"
              src="/header/header-logo.svg?_v=1638553701131"
              alt="header-logo"
            />
          </Link>
          <div className="header__menu menu">
            <button type="button" className="menu__icon icon-menu">
              <span></span>
            </button>
            <nav className="menu__body">
              <ul className="menu__list">
                <li className="menu__item">
                  <a href="https://solmate.co.za/become-an-agent/" className="menu__link">
                    Career
                  </a>
                </li>
                <li className="menu__item">
                  <Link
                    to="/pricing/"
                    className="menu__link"
                    activeClassName="pricing"
                    onClick={() => handleClick()}
                  >
                    Pricing
                  </Link>
                </li>
                <li className="menu__item">
                <a href="/help/" className="menu__link">
                    Help
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
