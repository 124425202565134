import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, lang }) {
  const { strapiSeo } = useStaticQuery(
    graphql`
      query {
        strapiSeo {
          description
          title
          meta_data {
            content
            name
          }
        }
      }
    `
  )
  const default_descr =
    "More than just a card, get paid, pay online, swipe, withdraw, purchase prepaid services."
  const default_title = strapiSeo.title || "SOLmate Debit Card"
  const metaDescription = description || strapiSeo.description || default_descr
  const additionalMeta = strapiSeo.meta_data || []

  const meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: `og:title`,
      content: default_title,
    },
    {
      name: `og:description`,
      content: metaDescription,
    },
  ].concat(additionalMeta)

  console.log(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={default_title ? `%s | ${default_title}` : null}
    >
      {meta.map((item, i) => (
        <meta name={item.name} content={item.content} key={`meta-${i}`} />
      ))}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `ru`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
